import { useState, useEffect } from 'react';
import { ARTICLES, ARTICLES_ASSIGNMENT } from '../../root/action-types';
import { useSelector } from 'react-redux';
import { pick } from 'lodash';
import Api from '../../api/call';
import { useDropbox } from '../../attachment/dropbox';

export const useSelectedArticles = (paperId = undefined) => {
  const [selected, setSelected] = useState({});
  const articles = useSelector((store) => store.articles.index);

  useEffect(() => {
    Api({
      request: ARTICLES,
    });
  }, []);

  const setPicked = (picked) => {
    picked.forEach((a, _) => {
      if (a.number && a.paperId === paperId) {
        setSelected((articles) => ({ ...articles, [a.number]: a }));
      }
    });
  };

  const setData = () => {
    const { loading, data } = articles;
    if (!loading) {
      const picked = data.map((a) => pick(a, ['id', 'number', 'title', 'attachment', 'paperId']));
      setPicked(picked);
    }
  };
  useEffect(setData, [articles]);

  const trigger = () => {
    setSelected({});
    setData();
  };

  useEffect(trigger, [paperId]);

  return [selected, setSelected];
};

const searchOption = ({ name, id }) => ({ title: name.split('.')[0], attachment: id });

const availableOptions = (options, selected) => {
  const used = selected.map((s) => s.attachment);
  return options.filter((o) => !used.includes(o.id)).map(searchOption);
};

export const useArticles = (paperId = undefined) => {
  const [selected, setSelected] = useSelectedArticles(paperId);
  const search = useSelector((store) => store.dropboxSearch);
  const options = availableOptions(search.files, Object.values(selected));

  const assign = (data) => {
    setSelected((articles) => ({ ...articles, [data.number]: data }));
    Api({ request: ARTICLES_ASSIGNMENT, data });
  };

  const { dropboxSearch, ready } = useDropbox();
  const searchQuery = () => {
    ready && dropboxSearch({ path: '/articles' });
  };

  useEffect(searchQuery, [ready]);

  return { selected, options, assign };
};

export default useArticles;
