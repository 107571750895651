import React, { useState, useEffect } from 'react';
import {
  ROUNDS_CREATE,
  ROUNDS_UPDATE,
  PROGRESS_ROUND,
  PAPERS_CREATE,
  GROUPS,
  ARTICLES_DESTROY,
} from '../../root/action-types';
import { useSelector } from 'react-redux';
import { get, uniq, isEqual, isEmpty } from 'lodash';
import Api from '../../api/call';
import propTypes from 'prop-types';
// Components:
import PaperPicker from '../../paper/PaperPicker';
import {
  Pane,
  Accordion,
  ButtonRow,
  SimpleButton,
  DateTimePicker,
  useArticles,
  useModal,
  useCurrentPaper,
  useCurrentRound,
  usePapers,
  useAllResponses,
} from '../../common/';
import CreateNotification from '../../notifications/Create';
import AdminModal from './AdminModal';
import CRQTasks from './CRQTasks';
import DeadlinePickers from './DeadlinePickers';
import MissedDeadlines from './MissedDeadlines';
// Assets:
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
// Utilites:
import MomentFormat from '../../root/MomentFormat';
import { next } from './utils';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

const notyf = new Notyf();

function AdminDashboard() {
  const { round: current } = useCurrentRound({ include_status: true });
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(null);
  const { papers, paperForStatus } = usePapers();
  const { currentPaper, setCurrentPaper, getDefaultPaper } = useCurrentPaper();
  const { maxPosition } = useSelector((store) => store.groups.index);
  const art = useSelector((store) => store.articles.article);
  const { openModal, closeModal, modalIsOpen } = useModal();
  const articles = useArticles(get(currentPaper, 'id'));
  const { responses } = useAllResponses();
  const defaultPaper = getDefaultPaper();
  const initialStatus = get(defaultPaper, 'status', 'initial');
  const [status, setStatus] = useState(initialStatus);
  const [open, setOpen] = useState(false);
  const initialDate = get(current, 'startDate', new Date());
  const [startDate, setStartDate] = useState(initialDate);

  const getStartDate = () => {
    if (get(current, 'startDate')) {
      setStartDate(current.startDate);
    }
  };

  useEffect(getStartDate, [initialDate]);

  const getStatus = () => {
    setStatus(initialStatus);
  };
  useEffect(getStatus, [initialStatus]);

  useEffect(() => {
    Api({ request: GROUPS });
  }, []);

  const onClick = (position) => {
    const close = isOpen === position;
    close ? setIsOpen(null) : setIsOpen(position);
    setSearch('');
  };

  const nextStage = () => {
    setStatus(next(status));

    Api({ request: PROGRESS_ROUND });
  };

  const updateStartDate = () => {
    Api({
      request: ROUNDS_UPDATE,
      id: current.id,
      data: { startDate: startDate },
    });
    setOpen(false);
  };

  const cancelStartDate = () => {
    setStartDate(current.startDate);
    setOpen(false);
  };

  const assignArticle = (item, position) => {
    setIsOpen({});
    const paperId = get(currentPaper, 'id');
    articles.assign({ ...item, number: position, paperId });
  };

  useEffect(() => {
    if (art?.errors?.length) {
      art.errors.forEach((err) => notyf.error({ message: err, duration: 5000 }));
    }
  }, [art?.errors]);

  const clearArticle = (index) => {
    const article = articles.selected[index];
    setIsOpen({});
    if (!article) {
      return;
    }
    Api({ request: ARTICLES_DESTROY, id: article.id });
  };

  const roundCreate = () => {
    Api({ request: ROUNDS_CREATE });
  };

  const paperCreate = () => {
    Api({ request: PAPERS_CREATE });
  };

  const Modal = () => {
    const onSubmit = () => {
      nextStage();
      closeModal();
    };

    return (
      <AdminModal closeModal={closeModal} modalIsOpen={modalIsOpen}>
        <h3>Are you sure you want to progress to the next stage?</h3>
        <div>
          Please ensure that all relevant admin tasks have been completed prior to continuing - this action cannot be
          reversed.
        </div>
        <br />
        <div>Would you like to continue?</div>
        <ButtonRow>
          <SimpleButton onClick={onSubmit} text="CONTINUE" />
          <SimpleButton onClick={closeModal} theme="muted" text="CANCEL" />
        </ButtonRow>
      </AdminModal>
    );
  };

  const buildLabel = (index) => {
    const article = articles.selected[index];
    return article && article.title ? article.title : `Assign Article User ${index}`;
  };

  const filterList = (data) => {
    return data
      .filter((item) => Object.values(articles.selected).every((a) => a.attachment !== item.id))
      .filter((item) => {
        if (search === null) return item;
        if (item.title.toLowerCase().includes(search.toLowerCase())) return item;
        return null;
      });
  };

  const papersSameStatus = (s) => {
    const statuses = papers && uniq(papers.map((p) => p.status));
    return isEqual(statuses, [s]);
  };

  const articleDisabled = (index) => {
    const article = articles.selected[index];
    const r = responses.find((r) => r.articleId === get(article, 'id'));

    return !!r;
  };

  const ArticleDropdown = (index) => (
    <div key={index} disabled={articleDisabled(index)} label={buildLabel(index)}>
      <input
        className="accordion-search"
        placeholder="Search in the article bank"
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className="list">{filterList(articles.options).map((item) => ListItem(item, index))}</div>
    </div>
  );

  const ListItem = (item, index) => (
    <div className="list-item" key={item.attachment} onClick={() => assignArticle(item, index)}>
      <FontAwesomeIcon icon={faFileAlt} />
      <div className="title">{item.title}</div>
    </div>
  );

  const buildArticleDropdowns = () => {
    let dropdowns = [];
    for (let i = 1; i <= parseInt(maxPosition); ++i) {
      dropdowns.push(ArticleDropdown(i));
    }
    return dropdowns;
  };

  return (
    <Pane>
      <h2>USERS CURRENT TASKS</h2>
      <div className="dashboard-pane-container">
        <CRQTasks openModal={openModal} paperForStatus={paperForStatus} currentStatus={get(current, 'currentStatus')} />
        <div className="dashboard-papers">
          <div className="round-date">
            {MomentFormat.shortDate(get(current, 'created_at'))}
            <SimpleButton onClick={() => setOpen(true)} theme="default" text="Edit Start Date" />
          </div>
          <div className="papers-list">
            <PaperPicker
              currentPaper={currentPaper}
              setCurrentPaper={setCurrentPaper}
              papers={papers}
              closeAccordion={() => setIsOpen({})}
              modify={true}
            />
          </div>
          <div className="dashboard-papers-controls">
            <button className="dashboard-add-paper" onClick={paperCreate} disabled={!papersSameStatus('initial')}>
              + Add Paper
            </button>
            <button className="dashboard-new-round" onClick={roundCreate} disabled={!papersSameStatus('complete')}>
              New Round
            </button>
          </div>
        </div>
        {open && (
          <div className="date-time">
            <div className="text">Date</div>
            <DateTimePicker onChange={(date) => setStartDate(date)} selected={new Date(startDate)} />
            <ButtonRow theme="date">
              <SimpleButton text="CANCEL" theme="muted-white" onClick={cancelStartDate} />
              <SimpleButton text="CONFIRM" theme="green" onClick={updateStartDate} />
            </ButtonRow>
          </div>
        )}
        <div className="dashboard-info-container">
          <div className="dashboard-articles">
            <div className="article-sub-title">Articles</div>
            <Accordion
              onClick={onClick}
              isOpen={isOpen}
              cancel={clearArticle}
              icon={faFileAlt}
              disabled={isEmpty(articles.options)}
            >
              {buildArticleDropdowns()}
            </Accordion>
          </div>
          <DeadlinePickers currentPaper={currentPaper} />
        </div>
        <div className="dashboard-notifications-container">
          <CreateNotification />
          <MissedDeadlines papers={papers} />
        </div>
      </div>
      {Modal()}
    </Pane>
  );
}

export default AdminDashboard;

AdminDashboard.propTypes = {
  current: propTypes.object,
  label: propTypes.string,
  paper: propTypes.string,
  icon: propTypes.node,
  alt: propTypes.string,
  position: propTypes.number,
  className: propTypes.string,
};
