import React from 'react';
import { useReducer, createContext } from 'react';
import Api from '../../api/call';
import { RESPONSES_UPDATE_MARKS, RESPONSES_UPDATE_ANSWERS } from '../../root/action-types';

export const QuestionsContext = createContext();

const useQuestions = () => {
  const [data, dispatch] = useReducer(questionReducer, []);

  // We use this to disable controls whilst a question is being editted
  // We need to do this cos state management is so janky :see_no_evil:
  const isQuestionActive = !!data.find((q) => !q.confirmed);

  return { data, dispatch, isQuestionActive };
};

export const QuestionProvider = ({ children }) => {
  const questions = useQuestions();
  return <QuestionsContext.Provider value={questions}>{children}</QuestionsContext.Provider>;
};

export const useQuestionsContext = (idx) => {
  const { dispatch, data } = React.useContext(QuestionsContext);

  return { dispatch, item: data[idx] };
};

function listCombineAnswers(state) {
  const items = state.map((item) => {
    if (item.name === 'list') {
      const newAnswer = item.list.map((_, i) => {
        const userAnswerNumber = ['userAnswer', i + 1].join('');
        return item[userAnswerNumber] || '';
      });
      return { ...item, userAnswer: newAnswer };
    }
    return item;
  });

  return items;
}

function fillableCombineAnswers(state) {
  const items = state.map((item) => {
    if (item.name !== 'fillable') return item;
    const userAnswer = typeof item.table === 'object' ? JSON.stringify(item.table) : item.table;
    return { ...item, userAnswer, table: item.originalTable };
  });
  return items;
}

function setUserAnswers(state, action) {
  const items = state.map((item) => {
    const newAnswer = { [action.userId]: { answer: item.userAnswer || '' } };
    if (item.hasOwnProperty('userAnswers')) {
      return { ...item, userAnswers: newAnswer };
    }
    return item;
  });
  return items;
}

function fillableExpandAnswers(state, action) {
  const items = state.map((item) => {
    if (item.name === 'fillable') {
      const tableAnswer = item.userAnswers[action.userId].answer;
      return { ...item, table: tableAnswer };
    }
    return item;
  });
  return items;
}
function setUserMarks(state, action) {
  const items = state.map((item) => {
    if (item.hasOwnProperty('userAnswers')) {
      const newUserMarks = {
        answer: item.userAnswers[action.userId].answer,
        awarded: item.awarded,
        comment: item.comment,
      };
      return {
        ...item,
        userAnswers: {
          ...item.userAnswers,
          [action.userId]: newUserMarks,
        },
      };
    }
    return item;
  });
  return items;
}

function listExpandAnswers(action) {
  const items = action.data;
  items.forEach((item) => {
    if (item.name === 'list') {
      const arrayAnswer = JSON.parse(item.userAnswers[action.userId].answer);
      arrayAnswer.forEach((a, i) => {
        const userAnswerNumber = ['userAnswer', i + 1].join('');
        Object.assign(item, { [userAnswerNumber]: a });
      });
    }
  });
  return items;
}

function setAnswers(state, action) {
  const items = state.map((item) => {
    if (item.hasOwnProperty('userAnswers')) {
      return { ...item, userAnswer: item.userAnswers[action.userId].answer };
    }
    return item;
  });
  return items;
}
function setMarks(state, action) {
  return state.map((item) => {
    if (item.hasOwnProperty('userAnswers')) {
      const marks = {
        comment: item.userAnswers[action.userId].comment,
        awarded: item.userAnswers[action.userId].awarded,
      };
      return { ...item, ...marks };
    }
    return item;
  });
}

function resetConfirmed(state) {
  state.forEach((item) => {
    item.confirmed = true;
  });
}

const insert = (arr, index, newItem) => {
  return [...arr.slice(0, index), newItem, ...arr.slice(index)];
};

const BLANK = {
  name: '',
  field: '',
  answer: '',
  guidance: '',
  type: '',
  marks: { label: '1 Mark', value: 1 },
  confirmed: false,
  list: [],
  table: [],
  userAnswers: {},
};

const SPORK = {
  ...BLANK,
  name: 'short',
  answer: 'Hooray! Should have added this so long ago...',
  marks: { label: '10 Marks', value: 10 },
  confirmed: true,
};

const questionReducer = (state, action) => {
  switch (action.type) {
    case 'add': {
      const items = state.map((item) => {
        if (item.confirmed) return item;
        return { ...item, confirmed: true };
      });

      const question = { ...BLANK, name: action.name, keyStamp: new Date() };

      if (action.index === undefined) {
        return [...items, question];
      }

      return insert(items, action.index, question);
    }
    case 'spork': {
      return [...state, { ...SPORK, field: '<p>strain</p>' }, { ...SPORK, field: '<p>injury</p>' }];
    }
    case 'remove': {
      return state.filter((_, i) => i !== action.index);
    }
    case 'update': {
      const items = state.map((item, i) => {
        if (action.index !== i) return item;
        const after = { ...item, [action.name]: action.value };
        return after;
      });
      return items;
    }
    case 'edit': {
      const items = state.map((item, i) => {
        if (action.index !== i && item.confirmed) return item;
        if (action.index !== i) return { ...item, confirmed: true };
        return { ...item, confirmed: false };
      });
      return items;
    }
    case 'confirm': {
      const items = state.map((item, i) => {
        if (action.index !== i) return item;
        return { ...action.question, confirmed: true };
      });
      return [...items];
    }
    case 'confirmAll': {
      const items = state.map((item) => {
        if (item.confirmed) return item;
        return { ...item, confirmed: true };
      });
      return items;
    }
    case 'cancel': {
      const items = state.map((item) => {
        if (item.confirmed) return item;
        return { ...item, confirmed: true };
      });
      const name = items[action.index].name;
      if (name !== 'clinical' && items[action.index].field === '') {
        return items.filter((_, i) => i !== action.index);
      }
      return [...items];
    }
    case 'listCombineAnswers': {
      return listCombineAnswers(state);
    }
    case 'listExpandAnswers': {
      return listExpandAnswers(action);
    }
    case 'fillableCombineAnswers': {
      return fillableCombineAnswers(state);
    }
    case 'fillableExpandAnswers': {
      return fillableExpandAnswers(state, action);
    }
    case 'setClinicalType': {
      const items = state.map((item) => {
        if (item.name === 'clinical' && item.type === '') {
          return { ...item, type: action.questionType };
        }
        return item;
      });
      return items;
    }
    case 'setAwarded': {
      const items = state.map((item, i) => {
        if (action.index !== i) return item;
        return { ...item, awarded: [action.value] };
      });
      return items;
    }
    case 'setMarks': {
      return setMarks(state, action);
    }
    case 'setUserMarks': {
      return setUserMarks(state, action);
    }
    case 'setAnswers': {
      return setAnswers(action);
    }
    case 'setUserAnswers': {
      return setUserAnswers(state, action);
    }
    case 'loadMarks': {
      let items = listExpandAnswers(action);
      items = fillableExpandAnswers(items, action);
      items = setAnswers(items, action);
      items = setMarks(items, action);
      return items.map((item) => {
        if (item.name === 'fillable') {
          return { ...item, confirmed: true, originalTable: item.table };
        }
        return { ...item, confirmed: true };
      });
    }
    case 'loadAnswers': {
      let items = listExpandAnswers(action);
      items = fillableExpandAnswers(items, action);
      items = setAnswers(items, action);
      return items.map((item) => {
        if (item.name === 'fillable') {
          return { ...item, confirmed: true, originalTable: item.table };
        }
        return { ...item, confirmed: true };
      });
    }
    case 'apiSubmitAnswers': {
      let items = listCombineAnswers(state);
      items = fillableCombineAnswers(items);
      items = setUserAnswers(items, action);
      Api({
        request: RESPONSES_UPDATE_ANSWERS,
        id: action.responseId,
        data: { questions: items, userId: Number(action.uid) },
      });
      return items;
    }
    case 'apiSubmitMarks': {
      const items = setUserMarks(state, action);
      Api({
        request: RESPONSES_UPDATE_MARKS,
        id: action.id,
        data: {
          questions: items,
          submitted: action.submitted,
          userId: action.userId,
          finalComment: action.finalComment,
        },
      });
      return items;
    }
    case 'reinitialize': {
      resetConfirmed(action.data);
      const items = action.data;
      items.forEach((item) => {
        if (item.name === 'fillable') {
          Object.assign(item, { originalTable: item.table });
        }
      });
      return [...action.data];
    }
    case 'resetMarks': {
      const items = state.map((item) => ({ ...item, comment: undefined, awarded: undefined }));
      return [...items];
    }
    case 'reset':
    default:
      return [];
  }
};
