const defaultConfig = {
  API_HTTP_SCHEME: process.env.REACT_APP_API_HTTP_SCHEME,
  API_WEBSOCKET_SCHEME: process.env.REACT_APP_API_WEBSOCKET_SCHEME,
  API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
  API_PORT: process.env.REACT_APP_API_PORT,
  ENV: process.env.REACT_APP_ENV,
  NODE_ENV: process.env.REACT_APP_NODE_ENV,
}

const getConfig = (name) => window._env_?.[name] ? window._env_[name] : defaultConfig[name]

export default getConfig