import React from 'react';
import Select from 'react-select';
import './InputDropdown.scss';

import { Field } from '../../common/Form/Field';

// Thin wrapper for react-select cos its API is a bit janky
const InputDropdown = ({ theme, error, label, onChange, value, options, ...props }) => {
  const css = ['simple-field'];

  if (theme) css.push(theme);
  if (error) css.push('error');

  const items = options.map((value) => ({ label: value, value }));

  const wrapper = (a) => {
    onChange(a?.map((v) => v.value));
  };

  const selectValues = value ? Array.from(value).map((v) => ({ value: v, label: v })) : [];

  return (
    <Field className={css.join(' ')} label={label}>
      <Select
        classNamePrefix={`${css.join(' ')} `}
        className={'dropdown'}
        onChange={wrapper}
        value={selectValues}
        options={items}
        {...props}
      />
    </Field>
  );
};
export default InputDropdown;
